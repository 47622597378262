import React from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import AuthenticationGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import TagContainer from '@enums/tagContainer';
import { Product } from '@interfaces/models/product';
import { PageType } from '@interfaces/common/common';
import { useAnalytics } from '@context/analytics.context';
import { useAuthentication } from '@context/authentication.context';
import { ProductAddToFavourite } from '@interfaces/models/internal/analytics-event/product-event';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useUserLikedProductsQuery from '@hooks/user/use-user-liked-products-query';
import { pickSearchContextAttributes } from '@helpers/utils/catalog/catalog-tracking-utils';
import useProfileQuery from '@hooks/profile/use-profile-query';
import useUser from '@hooks/user/use-user';
import { useRouter } from 'next/router';
import styles from './product-like-button.module.scss';

// This component was repurposed from general 'vc-like' to more specific product-only
//  because it behaves differently than the others. Styles can be reused from 'vc-like.module.scss'
interface AlertTracking {
  alertStatus: string;
  alertTitle: string;
}

export interface ProductLikeButtonProps {
  product: Product;
  showText: boolean;
  customClass?: string;
  testIdSuffix?: string;
  isFavorite?: boolean;
  isFavoritePage?: boolean;
  position?: number;
  parentPosition?: string;
  onPopupVisibilityChange?: (visible: boolean) => void;
  alertTracking?: AlertTracking;
  pageType: PageType;
  moduleType?: string;
}

const ProductLikeButton: React.FC<ProductLikeButtonProps> = (props): React.JSX.Element => {
  const {
    product,
    showText,
    customClass = '',
    testIdSuffix = '',
    isFavorite = false,
    position,
    parentPosition,
    pageType,
    moduleType,
    alertTracking,
  } = props;

  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();
  const { setRedirectUrl } = useAuthentication();
  const { likedProducts, likeProduct, unlikeProduct } = useUserLikedProductsQuery();
  const { sendAnalyticEvent: sendAlertAnalyticEvents } = useAnalyticEvents('alerts');
  const { sendAnalyticEvent: sendProductAnalyticEvents } = useAnalyticEvents('product');
  const { sendAnalyticEvent: sendCatalogAnalyticEvents } = useAnalyticEvents('catalog');
  const { sendAnalyticEvent: sendProfileAnalyticEvents } = useAnalyticEvents('profile_page');
  const { sendAnalyticEvent: sendFavoriteAnalyticEvents } = useAnalyticEvents('favorites');

  const isProductLiked: boolean = likedProducts.includes(product.id);
  const productLikeCount: number = (product.likeCount ?? 0) + (likedProducts.includes(product.id) ? 1 : 0);

  const { user } = useUser();
  const router = useRouter();

  const isProfileFavoritesPage = pageType === 'profile-favorites';
  const { isUserProfile } = useProfileQuery();
  const profileItemType = isUserProfile ? 'my' : 'member';
  const { favoritesUserSlug } = router?.query as {
    favoritesUserSlug: string;
  };
  const isUserFavoritesPage = favoritesUserSlug?.[0] === user?.id;

  const trackOnLikeProduct = () => {
    const sharedPayload = {
      product_id: String(product?.id || ''),
      seller_id: String(product?.seller?.id || ''),
      product_universe: product?.universe?.name ?? '',
      product_category: product?.category?.name ?? '',
      product_sub_category: product.subcategory?.name ?? '',
      product_brand: product?.brand?.name ?? '',
      product_unit_price_ati: ((product?.price?.cents ?? 0) / 100 ?? '').toString(),
      product_country: product?.seller?.country ?? '',
      product_position: String(position ?? ''),
      currency: product.price?.currency ?? '',
    };

    if (pageType === 'cms_page' || pageType === 'homepage') {
      sendEvent({
        type: 'event',
        payload: {
          event: 'pdp_add_favourites',
          ...sharedPayload,
          product_card_tag: product.tags?.join(',') ?? '',
          product_feed_category: moduleType,
          product_feed_position: parentPosition,
        },
      });
      return;
    }

    if (pageType === 'alerts') {
      sendAlertAnalyticEvents('prd_add_favourites', {
        product_id: product.id?.toString(),
        product_card_tag: alertTracking.alertStatus,
        product_feed_category: alertTracking.alertTitle,
        product_unit_price_ati: ((product?.price?.cents ?? 0) / 100).toString(),
        product_position: String(product?.position),
        currency: product.price?.currency,
      });
      return;
    }

    if (pageType !== 'favourite') {
      sendEvent(
        {
          type: 'event',
          container: TagContainer.Analytics,
          payload: {
            event: 'productAddToFavorite',
            addToFavoriteType: pageType,
            product_brand: product?.brand?.name,
            product_category: product?.category?.name,
            product_univers: product?.universe?.name,
            product_id: product?.id,
          } as ProductAddToFavourite,
        },
        {
          type: 'add_to_Fav',
          container: TagContainer.Media,
        } as never,
      );
      if (pageType === 'catalog') {
        sendCatalogAnalyticEvents('add_to_favourites', {
          label: product.id,
          ...pickSearchContextAttributes([
            'keyword',
            'index_name',
            'are_filters_active',
            'filters_details',
            'catalog_brand',
            'catalog_universe',
            'catalog_category',
            'catalog_subcategory',
            'search_query_id',
            'catalog_version',
            'nb_results',
          ]),
        });
        return;
      }
      sendProductAnalyticEvents('pdp_add_favourites', {
        label: product.id,
      });
    }

    if (pageType === 'profile-favorites' || pageType === 'profile-items-for-sale') {
      const product_feed_category = isProfileFavoritesPage
        ? `${profileItemType}_favourites`
        : `${profileItemType}_items_for_sale`;

      sendProfileAnalyticEvents('prd_add_favourites', {
        ...sharedPayload,
        product_feed_category,
      });
      return;
    }

    if (pageType === 'favourite') {
      sendFavoriteAnalyticEvents('prd_add_favourites', {
        ...sharedPayload,
        product_feed_category: isUserFavoritesPage ? 'my_favourites' : 'member_favourites',
      });
      return;
    }
  };

  const trackOnUnlikeProduct = () => {
    const pageTypesAvailable = ['favourite', 'cms_page', 'homepage', 'profile-items-for-sale', 'profile-favorites'];
    if (pageTypesAvailable.includes(pageType)) {
      const isCMSPage = pageType === 'cms_page' || pageType === 'homepage';
      sendEvent({
        type: 'event',
        payload: {
          event: isCMSPage ? 'pdp_remove_favourites' : 'prd_remove_favourites',
          product_id: String(product?.id || ''),
          seller_id: String(product?.seller?.id || ''),
          product_universe: product?.universe?.name ?? '',
          product_category: product?.category?.name ?? '',
          product_sub_category: product.subcategory?.name ?? '',
          product_brand: product?.brand?.name ?? '',
          product_unit_price_ati: ((product?.price?.cents ?? 0) / 100).toString(),
          product_country: product?.seller?.country ?? '',
          product_position: String(position ?? ''),
          currency: product.price?.currency,
          ...(isCMSPage
            ? {
                product_card_tag: product.tags?.join(',') ?? '',
                product_feed_category: moduleType,
                product_feed_position: parentPosition,
              }
            : {
                category: 'like',
                action: 'remove_from_favourites',
              }),
        },
      });
      return;
    }

    if (pageType === 'alerts') {
      sendAlertAnalyticEvents('prd_remove_favourites', {
        product_card_tag: alertTracking.alertStatus,
        product_feed_category: alertTracking.alertTitle,
        product_id: product.id?.toString(),
        product_unit_price_ati: ((product?.price?.cents ?? 0) / 100).toString(),
        product_position: String(product?.position),
        currency: product.price?.currency,
      });
      return;
    }

    if (pageType === 'catalog') {
      sendCatalogAnalyticEvents('remove_from_favourites', {
        label: product.id,
        ...pickSearchContextAttributes([
          'keyword',
          'index_name',
          'are_filters_active',
          'filters_details',
          'catalog_brand',
          'catalog_universe',
          'catalog_category',
          'catalog_subcategory',
          'search_query_id',
          'catalog_version',
          'nb_results',
        ]),
      });
      return;
    }

    sendProductAnalyticEvents('pdp_remove_favourites', {
      label: product.id,
    });

    if (pageType === 'favourite') {
      sendFavoriteAnalyticEvents('prd_remove_favourites', {
        product_feed_category: isUserFavoritesPage ? 'my_favourites' : 'member_favourites',
      });
      return;
    }

    if (pageType === 'profile-favorites' || pageType === 'profile-items-for-sale') {
      const isProfileFavoritesPage = pageType === 'profile-favorites';
      const product_feed_category = isProfileFavoritesPage
        ? `${profileItemType}_favourites`
        : `${profileItemType}_items_for_sale`;

      sendProfileAnalyticEvents('prd_remove_favourites', {
        product_feed_category,
      });
      return;
    }
  };

  const onClick = () => {
    if (isProductLiked) {
      trackOnUnlikeProduct();
      unlikeProduct(product.id);
    } else {
      trackOnLikeProduct();
      likeProduct(product.id);
    }
  };

  return (
    <AuthenticationGuard
      onAuth={onClick}
      onClick={() => setRedirectUrl(null)}
    >
      <button
        type="submit"
        aria-label={`${t('LIKE.I_LIKE_ACCESSIBILITY')} ${product.name}`}
        className={clsx(
          customClass,
          styles.like__button,
          { [styles['like__button--textHidden']]: !showText },
          { [styles['like__button--favorite']]: isFavorite },
        )}
        data-cy={`favorite_button${testIdSuffix}`}
        data-vc-dd-action-name={`like_button`}
        onClick={onClick}
      >
        {/* Show count in front of the icon if we don't show text */}
        {!showText && <span data-cy={`product-like-button-like-count-${product.id}`}>{productLikeCount}</span>}

        {/* 'Like' icon */}
        <span
          className={clsx(styles.like__icon, isProductLiked && styles['like__icon--liked'])}
          data-cy={
            isProductLiked ? `favorite_button__liked${testIdSuffix}` : `favorite_button__like__icon${testIdSuffix}`
          }
        />

        {/* Text and count */}
        {showText && (
          <span
            data-cy={`product-like-button-like-text-${product.id}`}
            className={styles.like__text}
          >
            {t('LIKE.I_LIKE', { count: productLikeCount })}
          </span>
        )}
      </button>
    </AuthenticationGuard>
  );
};

export default ProductLikeButton;

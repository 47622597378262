import React, { useEffect } from 'react';
import { GetServerSideProps, NextPageWithLayout } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { IContentTypeEntries } from '@constants/metas/default';
import { getOrCreateLDCookiesSsr } from '@components/bucketTest/bucket.utils';
import CMSPage from '@components/organisms/content-stack/cms-page';
import { ContentStackPageNames } from '@enums/contentStack';
import extractFromContext from '@helpers/utils/extract-from-context';
import ContentStackService from '@services/content-stack-service';
import logger from '@helpers/utils/logger/client';
import DefaultLayout from '@layouts/default-layout';
import { isRequest404Error, isRequestError } from '@helpers/errors';
import RequestError from '@errors/request-error';
import UserService from '@services/user-service';
import { User } from '@interfaces/models/user';
import { checkShouldShowBanner } from '@helpers/utils/general';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import { getAppInitialProps } from '@helpers/initial-props';

// Change the plan - Disable the feature until app is launched
// const ZendeskWebWidget = dynamic(() => import('@components/molecules/zendesk-web-widget'), { ssr: false });

type HomePageProps = {
  contentTypeEntries: IContentTypeEntries;
};

const HomePage: NextPageWithLayout<HomePageProps> = ({ contentTypeEntries }) => {
  const shouldShowBanner = checkShouldShowBanner(contentTypeEntries);
  const { hpScrollPosition, scrollToCmsElementId } = useRestoreHpScrollPosition();

  useEffect(() => {
    if (!!hpScrollPosition) {
      scrollToCmsElementId(hpScrollPosition);
    }
  }, []);

  return (
    <DefaultLayout shouldShowBanner={shouldShowBanner}>
      <CMSPage {...{ contentTypeEntries }} />
      {/* <ZendeskWebWidget /> */}
    </DefaultLayout>
  );
};

HomePage.Layout = [];
HomePage.PageType = 'HomePage';

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const initialProps = await getAppInitialProps(context);

    const { preferences, locale, sessionId, userId, serverAxiosRequestData } = extractFromContext(context);
    // TODO: The cmsFallback check is intended for QA testing purposes only.
    const cmsFallback = context?.query?.cmsFallback === 'true';
    const { serverAnonymousId } = getOrCreateLDCookiesSsr(context);
    let serverAnonymousIdWithTimestamp: string = null;

    if (sessionId && userId) {
      try {
        const MILLISECONDS_PER_SECOND = 1000;
        const user: User = await UserService.getUser(userId, serverAxiosRequestData);
        if (user?.inscriptionDate) {
          const inscriptionDateAsTimestampInSeconds =
            new Date(user.inscriptionDate).getTime() / MILLISECONDS_PER_SECOND;
          serverAnonymousIdWithTimestamp = `${serverAnonymousId};${inscriptionDateAsTimestampInSeconds}`;
        }
      } catch (e) {
        logger.error(`Error fetching user data: ${e}`);
      }
    }

    const apiRequests = [
      serverSideTranslations(locale, ['default-layout', 'common']),
      ContentStackService.getDataForCMSPage(
        ContentStackPageNames.HOME_PAGE,
        {
          ...preferences,
          userId,
          serverAnonymousId: serverAnonymousIdWithTimestamp ?? serverAnonymousId,
          csBranch: context?.query?.csBranch as string,
        },
        { cmsFallback },
      ),
    ];

    const promiseResults = await Promise.allSettled(apiRequests);

    const [translations, contentTypeEntries] = promiseResults.map((result, index) => {
      if (result.status === 'fulfilled') {
        return result.value;
      }

      if (!cmsFallback && index === 1) {
        throw new RequestError(result.reason);
      }

      return null;
    });

    // TODO: The cmsFallback check is intended for QA testing purposes only.
    // `/new-items` page handles the correct locale, so we're using it as a direct fallback if the CMS API fails.
    if (!contentTypeEntries && cmsFallback) {
      return {
        redirect: {
          destination: '/new-items/',
          permanent: false,
        },
      };
    }

    return {
      props: {
        ...initialProps,
        ...translations,
        contentTypeEntries: contentTypeEntries || {},
      },
    };
  } catch (err) {
    if (isRequestError(err)) {
      if (!isRequest404Error(err)) {
        logger.error(err, 'There was an API request error on the Home Page - getServerSideProps - index.page.tsx');
      }
      return {
        notFound: true,
      };
    }
    logger.error(err, 'The server failed to render the Home Page - getServerSideProps - index.page.tsx');
    throw err;
  }
};

export default HomePage;
